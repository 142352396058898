import { useCallback } from 'react';
import { triggerEvent as pushEventsToDataLake } from '@utils/events';
import { TraderEventType } from '../utils/eventsConstant';
import { EventsData } from 'constants/types';

const useEvents = () => {
  const triggerEvent = useCallback(
    async (
      traderEventType: keyof typeof TraderEventType,
      customEventData?: EventsData
    ) => {
      // getNativeUtils()?.recordCleverTapEvent?.(
      //   traderEventType,
      //   customEventData
      // );
      // registerCustomEvent(traderEventType, customEventData);
      pushEventsToDataLake(traderEventType, customEventData);
    },
    []
  );

  return { triggerEvent };
};

export default useEvents;
