export enum TraderEventType {
  Ac_Details_Initiate = 'ac_details_initiate',
  Home_Com_Profile_Initiate = 'Home_com_profile_initiate',
  Onboarding_Assurance_CA_Initiate = 'Onboarding_Assurance_CA_Initiate',
  NewOnboardEvent = 'NewOnboardEvent',
  ClientError = 'ClientError',
  ServerError = 'ServerError',
  VisitHomePage = 'VisitHomePage',
  BankDetailLinkClicked = 'BankDetailLinkClicked',
  ProceedForOnboard = 'ProceedForOnboard',
  Language_selected = 'Language_selected',
  Login_initiate = 'Login_initiate',
  Login_success = 'Login_success',
  Login_failed = 'Login_failed',
  Onboarding_initiate_new = 'Onboarding_initiate_new',
  Onboarding_success_new = 'Onboarding_success_new',
  Onboarding_failed_new = 'Onboarding_failed_new',
  Bank_details_inititate = 'Bank_details_inititate',
  Bank_details_success = 'Bank_details_success',
  Add_trade_initiate = 'Add_trade_initiate',
  Add_contract_initiate = 'Add_contract_initiate',
  Add_trade_success = 'Add_trade_success',
  Add_party_initiate = 'Add_party_initiate',
  Add_party_success = 'Add_party_success',
  Add_items_initiate = 'Add_items_initiate',
  Add_items_success = 'Add_items_success',
  Add_image_inititate = 'Add_image_inititate',
  Add_image_success = 'Add_image_success',
  Tracking_initiate = 'Tracking_initiate',
  Tracking_success = 'Tracking_success',
  Share_details_inititate = 'Share_details_inititate',
  VAN_share_details_initiate = 'VAN_share_details_initiate',
  Update_status_inititate = 'Update_status_inititate',
  Update_status_success = 'Update_status_success',
  Payment_flow_initiate = 'Payment_flow_initiate',
  Payment_flow_success = 'Payment_flow_success',
  Payment_status_initiate = 'Payment_status_initiate',
  Payment_status_success = 'Payment_status_success',
  Payment_status_pending = 'Payment_status_pending',
  Payment_status_failed = 'Payment_status_failed',
  Payment_statement_view = 'Payment_statement_view',
  Payment_initiate_non_app = 'Payment_initiate_non_app',
  Payment_details_initiate_non_app = 'Payment_details_initiate_non_app',
  Payment_details_verification_non_app = 'Payment_details_verification_non_app',
  Payment_status_initiate_non_app = 'Payment_status_initiate_non_app',
  Payment_status_success_non_app = 'Payment_status_success_non_app',
  Payment_status_pending_non_app = 'Payment_status_pending_non_app',
  Payment_status_failed_non_app = 'Payment_status_failed_non_app',
  Record_cash_pay_initiate = 'Record_cash_pay_initiate',
  Record_cash_pay_success = 'Record_cash_pay_success',
  Record_cash_pay_failed = 'Record_cash_pay_failed',
  Collect_initiate = 'Collect_initiate',
  Collect_status_initiate = 'Collect_status_initiate',
  Collect_status_success = 'Collect_status_success',
  Collect_status_failed = 'Collect_status_failed',
  Collect_request_initiate = 'Collect_request_initiate',
  Collect_request_success = 'Collect_request_success',
  Collect_request_failed = 'Collect_request_failed',
  Share_collect_request = 'Share_collect_request',
  Record_cash_collect_initiate = 'Record_cash_collect_initiate',
  Record_cash_collect_success = 'Record_cash_collect_success',
  Record_cash_collect_failed = 'Record_cash_collect_failed',
  Reward_initiate = 'Reward_initiate',
  Reward_type = 'Reward_type',
  Reward_status_initiate = 'Reward_status_initiate',
  Reward_scratch = 'Reward_scratch',
  Reward_credited = 'Reward_credited',
  Reward_statement = 'Reward_statement',
  Business_name_initiate = 'Business_name_initiate',
  Business_name_captured = 'Business_name_captured',
  Add_money_initiate = 'Add_money_initiate',
  Add_money_via_apps = 'Add_money_via_apps',
  Add_money_upi_request_success = 'Add_money_upi_request_success',
  Add_money_upi_request_failed = 'Add_money_upi_request_failed',
  Add_money_status_success = 'Add_money_success',
  Add_money_status_failed = 'Add_money_status_failed',
  Add_money_status_rejected = 'Add_money_status_rejected',
  Choose_party_initiate = 'Choose_party_initiate',
  Party_selected = 'Party_selected',
  View_party_trades = 'View_party_trades',
  View_trades = 'View_trades',
  View_notifications = 'View_notifications',
  Withdraw_money_initiate = 'Withdraw_money_initiate',
  Withdraw_money_success = 'Withdraw_money_success',
  Withdraw_money_failed = 'Withdraw_money_failed',
  View_party_transactions = 'View_party_transactions',
  View_transaction_status = 'View_transaction_status',
  Share_details_success = 'Share_details_success',
  VAN_share_details_success = 'VAN_share_details_success',
  VAN_share_details_failure = 'VAN_share_details_failure',
  Generate_withdraw_otp = 'Generate_withdraw_otp',
  Generate_pay_flow_otp = 'Generate_pay_flow_otp',
  Generate_login_otp = 'Generate_login_otp',
  Resend_Withdraw_OTP = 'Resend_Withdraw_OTP',
  Resend_Transfer_OTP = 'Resend_Transfer_OTP',
  Resend_Login_OTP = 'Resend_Login_OTP',
  Payee_bank_details_initiate = 'Payee_bank_details_initiate',
  Payee_bank_details_success = 'Payee_bank_details_success',
  Payee_bank_details_failed = 'Payee_bank_details_failed',
  Payee_account_verification_initiate = 'Payee_account_verification_initiate',
  Payee_account_verification_success = 'Payee_account_verification_success',
  Payee_account_verification_failed = 'Payee_account_verification_failed',
  Add_payee_bank_detail_initiate = 'Add_payee_bank_detail_initiate',
  Add_payee_bank_detail_success = 'Add_payee_bank_detail_success',
  Add_payee_bank_detail_failed = 'Add_payee_bank_detail_failed',
  Add_beneficiary_initiate = 'Add_beneficiary_initiate',
  Payee_upi_verification_initiate = 'Payee_upi_verification_initiate',
  Payee_upi_verification_success = 'Payee_upi_verification_success',
  Payee_upi_verification_failed = 'Payee_upi_verification_failed',
  Decline_payment_notification = 'Decline_payment_notification',
  Logout_user_account = 'Logout_user_account',
  Add_money_upi_verify_initiate = 'Add_money_upi_verify_initiate',
  Add_money_upi_verify_success = 'Add_money_upi_verify_success',
  Add_money_upi_verify_failed = 'Add_money_upi_verify_failed',
  Ekyc_action_summary_initiate = 'Ekyc_action_summary_initiate',
  Ekyc_action_summary_success = 'Ekyc_action_summary_success',
  Ekyc_action_summary_failed = 'Ekyc_action_summary_failed',
  Ekyc_start_verification = 'Ekyc_start_verification',
  Ekyc_skip_intro = 'Ekyc_skip_intro',
  Ekyc_document_status_initiate = 'Ekyc_document_status_initiate',
  Ekyc_document_status_success = 'Ekyc_document_status_success',
  Ekyc_document_status_failed = 'Ekyc_document_status_failed',
  Ekyc_callback_status_initiate = 'Ekyc_callback_status_initiate',
  Ekyc_callback_status_success = 'Ekyc_callback_status_success',
  Ekyc_callback_status_failed = 'Ekyc_callback_status_failed',
  Ekyc_start_video_verification = 'Ekyc_start_video_verification',
  Ekyc_digio_initiate = 'Ekyc_digio_initiate',
  Ekyc_digio_success = 'Ekyc_digio_success',
  Ekyc_digio_failed = 'Ekyc_digio_failed',
  Ekyc_srm_ticket_initiate = 'Ekyc_srm_ticket_initiate',
  Ekyc_srm_ticket_success = 'Ekyc_srm_ticket_success',
  Ekyc_srm_ticket_failed = 'Ekyc_srm_ticket_failed',
  Ekyc_license_doc_initiate = 'Ekyc_license_doc_initiate',
  Ekyc_license_doc_selected = 'Ekyc_license_doc_selected',
  Validate_withdraw_success = 'Validate_withdraw_success',
  Validate_withdraw_failed = 'Validate_withdraw_failed',
  Validate_payment_success = 'Validate_payment_success',
  Validate_payment_failed = 'Validate_payment_failed',
  View_Rewards_Initiate = 'View_Rewards_Initiate',
  View_Rewards_Initiate_Success = 'View_Rewards_Initiate_Success',
  View_Rewards_CashbackHistory_Initiate = 'View_Rewards_CashbackHistory_Initiate',
  View_Rewards_CashbackHistory_Initiate_Success = 'View_Rewards_CashbackHistory_Initiate_Success',
  Scratch_cards_scratched = 'Scratch_cards_scratched',
  Scratch_cards_scratched_Success = 'Scratch_cards_scratched_Success',
  Rewards_Back_button_initiate = 'Rewards_Back_button_initiate',
  Rewards_Back_button_initiate_success = 'Rewards_Back_button_initiate_success',
  View_Offers_Initiate = 'View_Offers_Initiate',
  View_Offers_Initiate_Success = 'View_Offers_Initiate_Success',
  View_All_redeemed_offers_Initiate = 'View_All_redeemed_offers_Initiate',
  View_All_redeemed_offers_Initiate_Success = 'View_All_redeemed_offers_Initiate_Success',
  Initiate_Offers_To_PayTo_Collect_Add_Trade = 'Initiate_Offers_To_PayTo_Collect_Add_Trade',
  Initiate_Offers_To_Pay_To_Collect_Add_Trade_Success = 'Initiate_Offers_To_Pay_To_Collect_Add_Trade_Success',
  View_Refer_Earn_Initiate = 'View_Refer_Earn_Initiate',
  View_Refer_Earn_Initiate_Success = 'View_Refer_Earn_Initiate_Success',
  View_Refer_Earn_CopyLink_Initiate = 'View_Refer_Earn_CopyLink_Initiate',
  View_Refer_Earn_CopyLink_Initiate_Success = 'View_Refer_Earn_CopyLink_Initiate_Success',
  View_Refer_Earn_SendInvite_Initiate = 'View_Refer_Earn_SendInvite_Initiate',
  View_Refer_Earn_SendInvite_Initiate_sucess = 'View_Refer_Earn_SendInvite_Initiate_sucess',
  View_Refer_Earn_SendInvite_Options = 'View_Refer_Earn_SendInvite_Options',
  View_Refer_Earn_SendInvite_Options_Success = 'View_Refer_Earn_SendInvite_Options_Success',
  Refer_Earn_Back_button_initiate = 'Refer_Earn_Back_button_initiate',
  Refer_Earn_Back_button_initiate_success = 'Refer_Earn_Back_button_initiate_success',
  View_ReferralEarnings_Initiate = 'View_ReferralEarnings_Initiate',
  View_ReferralEarnings_Initiate_success = 'View_ReferralEarnings_Initiate_success',
  View_ReferralEarnings_SendReminders_Initiate = 'View_ReferralEarnings_SendReminders_Initiate',
  View_ReferralEarnings_SendReminders_Initiate_success = 'View_ReferralEarnings_SendReminders_Initiate_success',
  ReferralEarning_Back_button_initiate = 'ReferralEarning_Back_button_initiate',
  ReferralEarnings_Back_button_initiate_success = 'ReferralEarnings_Back_button_initiate_success',
  QRCodeScanInitiate = 'QRCodeScanInitiate',
  Gp_Initiate = 'Gp_Initiate',
  Generic_event = 'Generic_event',
  Home_CTA_CBLinkBankAccount_Initiate = 'Home_CTA_CBLinkBankAccount_Initiate',
  Home_CBViewAccount_All_View = 'Home_CBViewAccount_All_View',
  Home_CBViewAccount_Select = 'Home_CBViewAccount_Select',

  Ninja_pay_credit_limit_failed = 'Ninja_pay_credit_limit_failed',
  Ninja_pay_request_failed = 'Ninja_pay_request_failed',
  Terms_initiate = 'Terms_initiate',
  Privacy_policy_initiate = 'Privacy_policy_initiate',
  Menu_language_initiate = 'Menu_language_initiate',
  Menu_language_success = 'Menu_language_success',
  Menu_language_failure = 'Menu_language_failure',
  Menu_help_initiate = 'Menu_help_initiate',
  Menu_logout_initiate = 'Menu_logout_initiate',
  Menu_select = 'Menu_select',
  Instaod_home_success = 'Instaod_home_success',
  Instaod_land_initiate = 'Instaod_land_initiate',
  Instaod_land_success = 'Instaod_land_success',
  MandiNews_Icon_Viewed = 'MandiNews_Icon_Viewed',
  NinjaPay_ShareInvite_Initiate = 'NinjaPay_ShareInvite_Initiate',
  NinjaPay_EnterReferrerNo_Error = 'NinjaPay_EnterReferrerNo_Error',
  OTP_autoread_initiate = 'OTP_autoread_initiate',
  OTP_autoread_successful = 'OTP_autoread_successful',
  OTP_autoread_failed = 'OTP_autoread_failed',
  OTP_autosubmit_initiate = 'OTP_autosubmit_initiate',
  OTP_autosubmit_success = 'OTP_autosubmit_success',
  OTP_autosubmit_failed = 'OTP_autosubmit_failed',
  Home_TradeConnect_Initiate = 'Home_TradeConnect_Initiate',
  Ninja_Card_Initiate = 'ninja_card_initiate',
  Initiate_Paysure_Homepage = 'Initiate_Paysure_Homepage',
  Homepage_Alert_View = 'Homepage_Alert_View',
  Homepage_AlertButton_Initiate = 'Homepage_AlertButton_Initiate',
  Price_HP_Initiate = 'Price_HP_Initiate',
  Homepage_ProductBanner_View_Top = 'Homepage_ProductBanner_View_Top',
  Homepage_ProductBanner_View_Bottom = 'Homepage_ProductBanner_View_Bottom',
  Homepage_ProductBanner_Click_Top = 'Homepage_ProductBanner_Click_Top',
  Homepage_ProductBanner_Click_Bottom = 'Homepage_ProductBanner_Click_Bottom',
  RCPV_OTP_Submit_Failure = 'RCPV_OTP_Submit_Failure',
  RCPV_OTP_Submit_Success = 'RCPV_OTP_Submit_Success',
  RCPV_OTP_Submit_Initiate = 'RCPV_OTP_Submit_Initiate',
  Ninja_account_initiate = 'Ninja_account_initiate',
  Exp_Bus_Initiate = 'Exp_Bus_Initiate',
  Payment_Assurance_Initiate = 'payment_assurance_initiate',
  Visit_Assurance_Home_Initiate = 'VisitAssuranceHomeInitiate',
  View_Contract_Initiate = 'View_Contract_Initiate',
  Instaod_inititate = 'Instaod_inititate',
  Track_HP_Initiate = 'Track_HP_Initiate',
  Investment_icon_initiate = 'Investment_icon_initiate',
  WIFI_ICON_CLICK = 'WIFI_ICON_CLICK',
  HP_commerce_buy = 'HP_commerce_buy',
  HP_commerce_sell = 'HP_commerce_sell',
  DT_Referral = 'DT referral',
  Ninja_Pay_HomePage = 'Ninja Pay HomePage',
  Subscription = 'Subscription',
  Atb_Icon_Click = 'ATB_Icon_Click',
  Scholarship_Apply = 'Scholarship_Apply',
}

export enum RefreshCustomEvents {
  VisitOrdersPage = 'VisitOrdersPage',
  VisitPartiesPage = 'VisitPartiesPage',
  VisitTradesPage = 'VisitTradesPage',
  VisitTransactionsPage = 'VisitTransactionsPage',
}

export enum urlToEventMapping {
  'terms-and-conditions' = 'Terms_success',
  'privacy-policy' = 'Privacy_policy_success',
  'notification' = 'notification_bell_select', //'Menu_notification_initiate',
  'rewards' = 'View_Rewards_Initiate',
  'refer' = 'View_Refer_Earn_Initiate',
  'upcoming-offers' = 'Offers_initiate',
}

export const TRADER_EVENT_SCHEMA_DETAIL: {
  [key in keyof typeof TraderEventType]: any;
} = {
  ServerError: {
    customEventSchemaId: 'Errors',
    customEventSchemaVersion: 1,
    eventType: 'Errors',
  },
  NewOnboardEvent: {
    customEventSchemaId: 'NewOnboardEvent',
    customEventSchemaVersion: 1,
    eventType: 'NewTrader',
  },
  ClientError: {
    customEventSchemaId: 'CLIENT_ERROR',
    customEventSchemaVersion: 1,
    eventType: 'ClientError',
  },
  VisitHomePage: {
    customEventSchemaId: 'VisitHomePage',
    customEventSchemaVersion: 1,
    eventType: 'VistingHomePage',
  },
  BankDetailLinkClicked: {
    customEventSchemaId: 'BANK_DETAIL_LINK_CLICKED',
    customEventSchemaVersion: 1,
    eventType: 'BankDetailLinkClicked',
  },
  ProceedForOnboard: {
    customEventSchemaId: 'PROCEED_FOR_ONBOARD',
    customEventSchemaVersion: 1,
    eventType: 'ProceedForOnboard',
  },
  Language_selected: {
    customEventSchemaId: TraderEventType.Language_selected,
    customEventSchemaVersion: 1,
    eventType: 'LanguageSelected',
  },
  Login_initiate: {
    customEventSchemaId: TraderEventType.Login_initiate,
    customEventSchemaVersion: 1,
    eventType: 'LoginInitiate',
  },
  Login_success: {
    customEventSchemaId: TraderEventType.Login_success,
    customEventSchemaVersion: 1,
    eventType: 'LoginSuccess',
  },
  Login_failed: {
    customEventSchemaId: TraderEventType.Login_failed,
    customEventSchemaVersion: 1,
    eventType: 'LoginFailed',
  },
  Onboarding_initiate_new: {
    customEventSchemaId: TraderEventType.Onboarding_initiate_new,
    customEventSchemaVersion: 1,
    eventType: 'OnboardingInitiateNew',
  },
  Onboarding_success_new: {
    customEventSchemaId: TraderEventType.Onboarding_success_new,
    customEventSchemaVersion: 1,
    eventType: 'OnboardingSuccessNew',
  },
  Onboarding_failed_new: {
    customEventSchemaId: TraderEventType.Onboarding_failed_new,
    customEventSchemaVersion: 1,
    eventType: 'OnboardingFailedNew',
  },
  Bank_details_inititate: {
    customEventSchemaId: TraderEventType.Bank_details_inititate,
    customEventSchemaVersion: 1,
    eventType: 'BankDetailsInititate',
  },
  Bank_details_success: {
    customEventSchemaId: TraderEventType.Bank_details_success,
    customEventSchemaVersion: 1,
    eventType: 'BankDetailsSuccess',
  },
  Add_trade_initiate: {
    customEventSchemaId: TraderEventType.Add_trade_initiate,
    customEventSchemaVersion: 1,
    eventType: 'AddTradeInitiate',
  },
  Add_contract_initiate: {
    customEventSchemaId: TraderEventType.Add_contract_initiate,
    customEventSchemaVersion: 1,
    eventType: 'AddContractInitiate',
  },
  Onboarding_Assurance_CA_Initiate: {
    customEventSchemaId: TraderEventType.Onboarding_Assurance_CA_Initiate,
    customEventSchemaVersion: 1,
    eventType: 'OnboardingAssuranceCAInitiate',
  },
  Add_trade_success: {
    customEventSchemaId: TraderEventType.Add_trade_success,
    customEventSchemaVersion: 1,
    eventType: 'AddTradeSuccess',
  },
  Add_party_initiate: {
    customEventSchemaId: TraderEventType.Add_party_initiate,
    customEventSchemaVersion: 1,
    eventType: 'AddPartyInitiate',
  },
  Add_party_success: {
    customEventSchemaId: TraderEventType.Add_party_success,
    customEventSchemaVersion: 1,
    eventType: 'AddPartySuccess',
  },
  Add_items_initiate: {
    customEventSchemaId: TraderEventType.Add_items_initiate,
    customEventSchemaVersion: 1,
    eventType: 'AddItemsInitiate',
  },
  Add_items_success: {
    customEventSchemaId: TraderEventType.Add_items_success,
    customEventSchemaVersion: 1,
    eventType: 'AddItemsSuccess',
  },
  Add_image_inititate: {
    customEventSchemaId: TraderEventType.Add_image_inititate,
    customEventSchemaVersion: 1,
    eventType: 'AddImageInititate',
  },
  Add_image_success: {
    customEventSchemaId: TraderEventType.Add_image_success,
    customEventSchemaVersion: 1,
    eventType: 'AddImageSuccess',
  },
  Tracking_initiate: {
    customEventSchemaId: TraderEventType.Tracking_initiate,
    customEventSchemaVersion: 1,
    eventType: 'TrackingInitiate',
  },
  Tracking_success: {
    customEventSchemaId: TraderEventType.Tracking_success,
    customEventSchemaVersion: 1,
    eventType: 'TrackingSuccess',
  },
  Share_details_inititate: {
    customEventSchemaId: TraderEventType.Share_details_inititate,
    customEventSchemaVersion: 1,
    eventType: 'ShareDetailsinititate',
  },
  VAN_share_details_initiate: {
    customEventSchemaId: TraderEventType.VAN_share_details_initiate,
    customEventSchemaVersion: 1,
    eventType: 'VAN_ShareDetailsInitiate',
  },
  Update_status_inititate: {
    customEventSchemaId: TraderEventType.Update_status_inititate,
    customEventSchemaVersion: 1,
    eventType: 'UpdateStatusInitiate',
  },
  Update_status_success: {
    customEventSchemaId: TraderEventType.Update_status_success,
    customEventSchemaVersion: 1,
    eventType: 'UpdateStatusSuccess',
  },
  Payment_flow_initiate: {
    customEventSchemaId: TraderEventType.Payment_flow_initiate,
    customEventSchemaVersion: 1,
    eventType: 'PaymentFlowInitiate',
  },
  Payment_flow_success: {
    customEventSchemaId: TraderEventType.Payment_flow_success,
    customEventSchemaVersion: 1,
    eventType: 'PaymentFlowSuccess',
  },
  Payment_status_initiate: {
    customEventSchemaId: TraderEventType.Payment_status_initiate,
    customEventSchemaVersion: 1,
    eventType: 'PaymentStatusInitiate',
  },
  Payment_status_success: {
    customEventSchemaId: TraderEventType.Payment_status_success,
    customEventSchemaVersion: 1,
    eventType: 'PaymentStatusSuccess',
  },
  Payment_status_pending: {
    customEventSchemaId: TraderEventType.Payment_status_pending,
    customEventSchemaVersion: 1,
    eventType: 'PaymentStatusPending',
  },
  Payment_status_failed: {
    customEventSchemaId: TraderEventType.Payment_status_failed,
    customEventSchemaVersion: 1,
    eventType: 'PaymentStatusFailed',
  },
  Payment_statement_view: {
    customEventSchemaId: 'Payment_statement_view',
    customEventSchemaVersion: 1,
    eventType: 'PaymentStatementView',
  },
  Payment_initiate_non_app: {
    customEventSchemaId: TraderEventType.Payment_initiate_non_app,
    customEventSchemaVersion: 1,
    eventType: 'PaymentInitiateNonapp',
  },
  Payment_details_initiate_non_app: {
    customEventSchemaId: TraderEventType.Payment_details_initiate_non_app,
    customEventSchemaVersion: 1,
    eventType: 'PaymentDetailsInitiateNonapp',
  },
  Payment_details_verification_non_app: {
    customEventSchemaId: TraderEventType.Payment_details_verification_non_app,
    customEventSchemaVersion: 1,
    eventType: 'PaymentDetailsVerificationNonapp',
  },
  Payment_status_initiate_non_app: {
    customEventSchemaId: TraderEventType.Payment_status_initiate_non_app,
    customEventSchemaVersion: 1,
    eventType: 'PaymentStatusInitiateNonapp',
  },
  Payment_status_success_non_app: {
    customEventSchemaId: TraderEventType.Payment_status_success_non_app,
    customEventSchemaVersion: 1,
    eventType: 'PaymentStatusSuccessNonapp',
  },
  Payment_status_pending_non_app: {
    customEventSchemaId: TraderEventType.Payment_status_pending_non_app,
    customEventSchemaVersion: 1,
    eventType: 'PaymentStatusPendingNonapp',
  },
  Payment_status_failed_non_app: {
    customEventSchemaId: TraderEventType.Payment_status_failed_non_app,
    customEventSchemaVersion: 1,
    eventType: 'PaymentStatusFailedNonapp',
  },
  Collect_initiate: {
    customEventSchemaId: TraderEventType.Collect_initiate,
    customEventSchemaVersion: 1,
    eventType: 'CollectInitiate',
  },
  Collect_status_initiate: {
    customEventSchemaId: TraderEventType.Collect_status_initiate,
    customEventSchemaVersion: 1,
    eventType: 'CollectStatusInitiate',
  },
  Collect_status_success: {
    customEventSchemaId: TraderEventType.Collect_status_success,
    customEventSchemaVersion: 1,
    eventType: 'CollectStatusSuccess',
  },
  Collect_status_failed: {
    customEventSchemaId: TraderEventType.Collect_status_failed,
    customEventSchemaVersion: 1,
    eventType: 'CollectStatusFailed',
  },
  Reward_initiate: {
    customEventSchemaId: TraderEventType.Reward_initiate,
    customEventSchemaVersion: 1,
    eventType: 'RewardInitiate',
  },
  Reward_type: {
    customEventSchemaId: TraderEventType.Reward_type,
    customEventSchemaVersion: 1,
    eventType: 'RewardType',
  },
  Reward_status_initiate: {
    customEventSchemaId: TraderEventType.Reward_status_initiate,
    customEventSchemaVersion: 1,
    eventType: 'RewardStatusInitiate',
  },
  Reward_scratch: {
    customEventSchemaId: TraderEventType.Reward_scratch,
    customEventSchemaVersion: 1,
    eventType: 'RewardScratch',
  },
  Reward_credited: {
    customEventSchemaId: TraderEventType.Reward_credited,
    customEventSchemaVersion: 1,
    eventType: 'RewardCredited',
  },
  Reward_statement: {
    customEventSchemaId: TraderEventType.Reward_statement,
    customEventSchemaVersion: 1,
    eventType: 'RewardStatement',
  },
  Business_name_initiate: {
    customEventSchemaId: TraderEventType.Business_name_initiate,
    customEventSchemaVersion: 1,
    eventType: 'BusinessNameInitiate',
  },
  Business_name_captured: {
    customEventSchemaId: TraderEventType.Business_name_captured,
    customEventSchemaVersion: 1,
    eventType: 'BusinessNameCaptured',
  },
  Add_money_initiate: {
    customEventSchemaId: TraderEventType.Add_money_initiate,
    customEventSchemaVersion: 1,
    eventType: 'AddMoneyInitiate',
  },
  Add_money_via_apps: {
    customEventSchemaId: TraderEventType.Add_money_via_apps,
    customEventSchemaVersion: 1,
    eventType: 'AddMoneyViaApps',
  },
  Add_money_upi_request_success: {
    customEventSchemaId: TraderEventType.Add_money_upi_request_success,
    customEventSchemaVersion: 1,
    eventType: 'AddMoneyUpiRequestSuccess',
  },
  Add_money_upi_request_failed: {
    customEventSchemaId: TraderEventType.Add_money_upi_request_failed,
    customEventSchemaVersion: 1,
    eventType: 'AddMoneyUpiRequestFailed',
  },
  Add_money_status_success: {
    customEventSchemaId: TraderEventType.Add_money_status_success,
    customEventSchemaVersion: 1,
    eventType: 'AddMoneyStatusSuccess',
  },
  Add_money_status_failed: {
    customEventSchemaId: TraderEventType.Add_money_status_failed,
    customEventSchemaVersion: 1,
    eventType: 'AddMoneyStatusFailed',
  },
  Add_money_status_rejected: {
    customEventSchemaId: TraderEventType.Add_money_status_rejected,
    customEventSchemaVersion: 1,
    eventType: 'AddMoneyStatusRejected',
  },
  Choose_party_initiate: {
    customEventSchemaId: TraderEventType.Choose_party_initiate,
    customEventSchemaVersion: 1,
    eventType: 'ChoosePartyInitiate',
  },
  Party_selected: {
    customEventSchemaId: TraderEventType.Party_selected,
    customEventSchemaVersion: 1,
    eventType: 'PartySelected',
  },
  View_party_trades: {
    customEventSchemaId: TraderEventType.View_party_trades,
    customEventSchemaVersion: 1,
    eventType: 'ViewPartyTrades',
  },
  View_trades: {
    customEventSchemaId: TraderEventType.View_trades,
    customEventSchemaVersion: 1,
    eventType: 'ViewTrades',
  },
  View_notifications: {
    customEventSchemaId: TraderEventType.View_notifications,
    customEventSchemaVersion: 1,
    eventType: 'ViewNotifications',
  },
  Withdraw_money_initiate: {
    customEventSchemaId: TraderEventType.Withdraw_money_initiate,
    customEventSchemaVersion: 1,
    eventType: 'WithdrawMoneyInitiate',
  },
  Withdraw_money_success: {
    customEventSchemaId: TraderEventType.Withdraw_money_success,
    customEventSchemaVersion: 1,
    eventType: 'WithdrawMoneySuccess',
  },
  View_party_transactions: {
    customEventSchemaId: TraderEventType.View_party_transactions,
    customEventSchemaVersion: 1,
    eventType: 'ViewTransactions',
  },
  View_transaction_status: {
    customEventSchemaId: TraderEventType.View_transaction_status,
    customEventSchemaVersion: 1,
    eventType: 'ViewTransactionStatus',
  },
  Collect_request_initiate: {
    customEventSchemaId: TraderEventType.Collect_request_initiate,
    customEventSchemaVersion: 1,
    eventType: 'CollectRequestInitiate',
  },
  Collect_request_success: {
    customEventSchemaId: TraderEventType.Collect_request_success,
    customEventSchemaVersion: 1,
    eventType: 'CollectRequestSuccess',
  },
  Collect_request_failed: {
    customEventSchemaId: TraderEventType.Collect_request_failed,
    customEventSchemaVersion: 1,
    eventType: 'CollectRequestFailed',
  },
  Share_collect_request: {
    customEventSchemaId: TraderEventType.Share_collect_request,
    customEventSchemaVersion: 1,
    eventType: 'ShareCollectRequest',
  },
  Record_cash_collect_initiate: {
    customEventSchemaId: TraderEventType.Record_cash_collect_initiate,
    customEventSchemaVersion: 1,
    eventType: 'RecordCashCollectInitiate',
  },
  Record_cash_collect_success: {
    customEventSchemaId: TraderEventType.Record_cash_collect_success,
    customEventSchemaVersion: 1,
    eventType: 'RecordCashCollectSuccess',
  },
  Record_cash_collect_failed: {
    customEventSchemaId: TraderEventType.Record_cash_collect_failed,
    customEventSchemaVersion: 1,
    eventType: 'RecordCashCollectFailed',
  },
  Share_details_success: {
    customEventSchemaId: TraderEventType.Share_details_success,
    customEventSchemaVersion: 1,
    eventType: 'ShareDetailsSuccess',
  },
  VAN_share_details_success: {
    customEventSchemaId: TraderEventType.VAN_share_details_success,
    customEventSchemaVersion: 1,
    eventType: 'VAN_ShareDetailsSuccess',
  },
  VAN_share_details_failure: {
    customEventSchemaId: TraderEventType.VAN_share_details_failure,
    customEventSchemaVersion: 1,
    eventType: 'VAN_ShareDetailsFailure',
  },
  Withdraw_money_failed: {
    customEventSchemaId: TraderEventType.Withdraw_money_failed,
    customEventSchemaVersion: 1,
    eventType: 'WithdrawMoneyFailed',
  },
  Record_cash_pay_initiate: {
    customEventSchemaId: TraderEventType.Record_cash_pay_initiate,
    customEventSchemaVersion: 1,
    eventType: 'RecordCashPayInitiate',
  },
  Record_cash_pay_success: {
    customEventSchemaId: TraderEventType.Record_cash_pay_success,
    customEventSchemaVersion: 1,
    eventType: 'RecordCashPaySuccess',
  },
  Record_cash_pay_failed: {
    customEventSchemaId: TraderEventType.Record_cash_pay_failed,
    customEventSchemaVersion: 1,
    eventType: 'RecordCashPayFailed',
  },
  Generate_withdraw_otp: {
    customEventSchemaId: TraderEventType.Generate_withdraw_otp,
    customEventSchemaVersion: 1,
    eventType: 'GenerateWithdrawOtp',
  },
  Generate_pay_flow_otp: {
    customEventSchemaId: TraderEventType.Generate_pay_flow_otp,
    customEventSchemaVersion: 1,
    eventType: 'GeneratePayFlowOtp',
  },
  Generate_login_otp: {
    customEventSchemaId: TraderEventType.Generate_login_otp,
    customEventSchemaVersion: 1,
    eventType: 'GenerateLoginOtp',
  },
  Resend_Withdraw_OTP: {
    customEventSchemaId: TraderEventType.Resend_Withdraw_OTP,
    customEventSchemaVersion: 1,
    eventType: 'ResendWithdrawOTP',
  },
  Resend_Transfer_OTP: {
    customEventSchemaId: TraderEventType.Resend_Transfer_OTP,
    customEventSchemaVersion: 1,
    eventType: 'ResendTransferOTP',
  },
  Resend_Login_OTP: {
    customEventSchemaId: TraderEventType.Resend_Login_OTP,
    customEventSchemaVersion: 1,
    eventType: 'ResendLoginOTP',
  },
  Payee_bank_details_initiate: {
    customEventSchemaId: TraderEventType.Payee_bank_details_initiate,
    customEventSchemaVersion: 1,
    eventType: 'PayeeBankDetailsInitiate',
  },
  Payee_bank_details_success: {
    customEventSchemaId: TraderEventType.Payee_bank_details_success,
    customEventSchemaVersion: 1,
    eventType: 'PayeeBankDetailsSuccess',
  },
  Payee_bank_details_failed: {
    customEventSchemaId: TraderEventType.Payee_bank_details_failed,
    customEventSchemaVersion: 1,
    eventType: 'PayeeBankDetailsFailed',
  },
  Payee_account_verification_initiate: {
    customEventSchemaId: TraderEventType.Payee_account_verification_initiate,
    customEventSchemaVersion: 1,
    eventType: 'PayeeAccountVerificationInitiate',
  },
  Payee_account_verification_success: {
    customEventSchemaId: TraderEventType.Payee_account_verification_success,
    customEventSchemaVersion: 1,
    eventType: 'PayeeAccountVerificationSuccess',
  },
  Payee_account_verification_failed: {
    customEventSchemaId: TraderEventType.Payee_account_verification_failed,
    customEventSchemaVersion: 1,
    eventType: 'PayeeAccountVerificationFailed',
  },
  Add_payee_bank_detail_initiate: {
    customEventSchemaId: TraderEventType.Add_payee_bank_detail_initiate,
    customEventSchemaVersion: 1,
    eventType: 'AddPayeeBankDetailInitiate',
  },
  Add_payee_bank_detail_success: {
    customEventSchemaId: TraderEventType.Add_payee_bank_detail_success,
    customEventSchemaVersion: 1,
    eventType: 'AddPayeeBankDetailSuccess',
  },
  Add_payee_bank_detail_failed: {
    customEventSchemaId: TraderEventType.Add_payee_bank_detail_failed,
    customEventSchemaVersion: 1,
    eventType: 'AddPayeeBankDetailFailed',
  },
  Add_beneficiary_initiate: {
    customEventSchemaId: TraderEventType.Add_beneficiary_initiate,
    customEventSchemaVersion: 1,
    eventType: 'AddBeneficiaryInitiate',
  },
  Payee_upi_verification_initiate: {
    customEventSchemaId: TraderEventType.Payee_upi_verification_initiate,
    customEventSchemaVersion: 1,
    eventType: 'PayeeUpiVerificationInitiate',
  },
  Payee_upi_verification_success: {
    customEventSchemaId: TraderEventType.Payee_upi_verification_success,
    customEventSchemaVersion: 1,
    eventType: 'PayeeUpiVerificationSuccess',
  },
  Payee_upi_verification_failed: {
    customEventSchemaId: TraderEventType.Payee_upi_verification_failed,
    customEventSchemaVersion: 1,
    eventType: 'PayeeUpiVerificationFailed',
  },
  Decline_payment_notification: {
    customEventSchemaId: TraderEventType.Decline_payment_notification,
    customEventSchemaVersion: 1,
    eventType: 'DeclinePaymentNotification',
  },
  Logout_user_account: {
    customEventSchemaId: TraderEventType.Logout_user_account,
    customEventSchemaVersion: 1,
    eventType: 'LogoutUserAccount',
  },
  Add_money_upi_verify_initiate: {
    customEventSchemaId: TraderEventType.Add_money_upi_verify_initiate,
    customEventSchemaVersion: 1,
    eventType: 'AddMoneyUpiVerifyInitiate',
  },
  Add_money_upi_verify_success: {
    customEventSchemaId: TraderEventType.Add_money_upi_verify_success,
    customEventSchemaVersion: 1,
    eventType: 'AddMoneyUpiVerifySuccess',
  },
  Add_money_upi_verify_failed: {
    customEventSchemaId: TraderEventType.Add_money_upi_verify_failed,
    customEventSchemaVersion: 1,
    eventType: 'AddMoneyUpiVerifyFailed',
  },
  Ekyc_action_summary_initiate: {
    customEventSchemaId: TraderEventType.Ekyc_action_summary_initiate,
    customEventSchemaVersion: 1,
    eventType: 'EkycActionSummaryInitiate',
  },
  Ekyc_action_summary_success: {
    customEventSchemaId: TraderEventType.Ekyc_action_summary_success,
    customEventSchemaVersion: 1,
    eventType: 'EkycActionSummarySuccess',
  },
  Ekyc_action_summary_failed: {
    customEventSchemaId: TraderEventType.Ekyc_action_summary_failed,
    customEventSchemaVersion: 1,
    eventType: 'EkycActionSummaryFailed',
  },
  Ekyc_start_verification: {
    customEventSchemaId: TraderEventType.Ekyc_start_verification,
    customEventSchemaVersion: 1,
    eventType: 'EkycStartVerification',
  },
  Ekyc_skip_intro: {
    customEventSchemaId: TraderEventType.Ekyc_skip_intro,
    customEventSchemaVersion: 1,
    eventType: 'EkycSkipIntro',
  },
  Ekyc_document_status_initiate: {
    customEventSchemaId: TraderEventType.Ekyc_document_status_initiate,
    customEventSchemaVersion: 1,
    eventType: 'EkyDocumentStatusInitiate',
  },
  Ekyc_document_status_success: {
    customEventSchemaId: TraderEventType.Ekyc_document_status_success,
    customEventSchemaVersion: 1,
    eventType: 'EkyDocumentStatusSuccess',
  },
  Ekyc_document_status_failed: {
    customEventSchemaId: TraderEventType.Ekyc_document_status_failed,
    customEventSchemaVersion: 1,
    eventType: 'EkyDocumentStatusFailed',
  },
  Ekyc_start_video_verification: {
    customEventSchemaId: TraderEventType.Ekyc_start_video_verification,
    customEventSchemaVersion: 1,
    eventType: 'EkycStartVideoVerification',
  },
  Ekyc_digio_initiate: {
    customEventSchemaId: TraderEventType.Ekyc_digio_initiate,
    customEventSchemaVersion: 1,
    eventType: 'EkycDigioInitiate',
  },
  Ekyc_digio_success: {
    customEventSchemaId: TraderEventType.Ekyc_digio_success,
    customEventSchemaVersion: 1,
    eventType: 'EkycDigioSuccess',
  },
  Ekyc_digio_failed: {
    customEventSchemaId: TraderEventType.Ekyc_digio_failed,
    customEventSchemaVersion: 1,
    eventType: 'EkycDigioFailed',
  },
  Ekyc_srm_ticket_initiate: {
    customEventSchemaId: TraderEventType.Ekyc_srm_ticket_initiate,
    customEventSchemaVersion: 1,
    eventType: 'EkycSrmTicketInitiate',
  },
  Ekyc_srm_ticket_success: {
    customEventSchemaId: TraderEventType.Ekyc_srm_ticket_success,
    customEventSchemaVersion: 1,
    eventType: 'EkycSrmTicketSuccess',
  },
  Ekyc_srm_ticket_failed: {
    customEventSchemaId: TraderEventType.Ekyc_srm_ticket_failed,
    customEventSchemaVersion: 1,
    eventType: 'EkycSrmTicketFailed',
  },
  Ekyc_callback_status_initiate: {
    customEventSchemaId: TraderEventType.Ekyc_callback_status_initiate,
    customEventSchemaVersion: 1,
    eventType: 'EkyCallbackStatusInitiate',
  },
  Ekyc_callback_status_success: {
    customEventSchemaId: TraderEventType.Ekyc_callback_status_success,
    customEventSchemaVersion: 1,
    eventType: 'EkyCallbackStatusSuccess',
  },
  Ekyc_callback_status_failed: {
    customEventSchemaId: TraderEventType.Ekyc_callback_status_failed,
    customEventSchemaVersion: 1,
    eventType: 'EkyCallbackStatusFailed',
  },
  Ekyc_license_doc_initiate: {
    customEventSchemaId: TraderEventType.Ekyc_license_doc_initiate,
    customEventSchemaVersion: 1,
    eventType: 'EkycLicenseDocInitiate',
  },
  Ekyc_license_doc_selected: {
    customEventSchemaId: TraderEventType.Ekyc_license_doc_selected,
    customEventSchemaVersion: 1,
    eventType: 'EkycLicenseDocumentSelected',
  },
  Validate_withdraw_success: {
    customEventSchemaId: TraderEventType.Validate_withdraw_success,
    customEventSchemaVersion: 1,
    eventType: 'ValidateWithdrawSuccess',
  },
  Validate_withdraw_failed: {
    customEventSchemaId: TraderEventType.Validate_withdraw_failed,
    customEventSchemaVersion: 1,
    eventType: 'ValidateWithdrawFailed',
  },
  Validate_payment_success: {
    customEventSchemaId: TraderEventType.Validate_payment_success,
    customEventSchemaVersion: 1,
    eventType: 'ValidatePaymentSuccess',
  },
  Validate_payment_failed: {
    customEventSchemaId: TraderEventType.Validate_payment_failed,
    customEventSchemaVersion: 1,
    eventType: 'ValidatePaymentFailed',
  },
  View_Rewards_Initiate: {
    customEventSchemaId: TraderEventType.View_Rewards_Initiate,
    customEventSchemaVersion: 1,
    eventType: 'View_Rewards_Initiate',
  },
  View_Rewards_Initiate_Success: {
    customEventSchemaId: TraderEventType.View_Rewards_Initiate_Success,
    customEventSchemaVersion: 1,
    eventType: 'View_Rewards_Initiate_Success',
  },
  View_Rewards_CashbackHistory_Initiate: {
    customEventSchemaId: TraderEventType.View_Rewards_CashbackHistory_Initiate,
    customEventSchemaVersion: 1,
    eventType: 'View_Rewards_CashbackHistory_Initiate',
  },
  View_Rewards_CashbackHistory_Initiate_Success: {
    customEventSchemaId:
      TraderEventType.View_Rewards_CashbackHistory_Initiate_Success,
    customEventSchemaVersion: 1,
    eventType: 'View_Rewards_CashbackHistory_Initiate_Success',
  },
  Scratch_cards_scratched: {
    customEventSchemaId: TraderEventType.Scratch_cards_scratched,
    customEventSchemaVersion: 1,
    eventType: 'Scratch_cards_scratched',
  },
  Scratch_cards_scratched_Success: {
    customEventSchemaId: TraderEventType.Scratch_cards_scratched_Success,
    customEventSchemaVersion: 1,
    eventType: 'Scratch_cards_scratched_Success',
  },
  Rewards_Back_button_initiate: {
    customEventSchemaId: TraderEventType.Rewards_Back_button_initiate,
    customEventSchemaVersion: 1,
    eventType: 'Rewards_Back_button_initiate',
  },
  Rewards_Back_button_initiate_success: {
    customEventSchemaId: TraderEventType.Rewards_Back_button_initiate_success,
    customEventSchemaVersion: 1,
    eventType: 'Rewards_Back_button_initiate_success',
  },
  View_Offers_Initiate: {
    customEventSchemaId: TraderEventType.View_Offers_Initiate,
    customEventSchemaVersion: 1,
    eventType: 'View_Offers_Initiate',
  },
  View_Offers_Initiate_Success: {
    customEventSchemaId: TraderEventType.View_Offers_Initiate_Success,
    customEventSchemaVersion: 1,
    eventType: 'View_Offers_Initiate_Success',
  },
  View_All_redeemed_offers_Initiate: {
    customEventSchemaId: TraderEventType.View_All_redeemed_offers_Initiate,
    customEventSchemaVersion: 1,
    eventType: 'View_All_redeemed_offers_Initiate',
  },
  View_All_redeemed_offers_Initiate_Success: {
    customEventSchemaId:
      TraderEventType.View_All_redeemed_offers_Initiate_Success,
    customEventSchemaVersion: 1,
    eventType: 'View_All_redeemed_offers_Initiate_Success',
  },
  Initiate_Offers_To_PayTo_Collect_Add_Trade: {
    customEventSchemaId:
      TraderEventType.Initiate_Offers_To_PayTo_Collect_Add_Trade,
    customEventSchemaVersion: 1,
    eventType: 'Initiate_Offers_To_PayTo_Collect_Add_Trade',
  },
  Initiate_Offers_To_Pay_To_Collect_Add_Trade_Success: {
    customEventSchemaId:
      TraderEventType.Initiate_Offers_To_Pay_To_Collect_Add_Trade_Success,
    customEventSchemaVersion: 1,
    eventType: 'Initiate_Offers_To_Pay_To_Collect_Add_Trade_Success',
  },
  View_Refer_Earn_Initiate: {
    customEventSchemaId: TraderEventType.View_Refer_Earn_Initiate,
    customEventSchemaVersion: 1,
    eventType: 'View_Refer_Earn_Initiate',
  },
  View_Refer_Earn_Initiate_Success: {
    customEventSchemaId: TraderEventType.View_Refer_Earn_Initiate_Success,
    customEventSchemaVersion: 1,
    eventType: 'View_Refer_Earn_Initiate_Success',
  },
  View_Refer_Earn_CopyLink_Initiate: {
    customEventSchemaId: TraderEventType.View_Refer_Earn_CopyLink_Initiate,
    customEventSchemaVersion: 1,
    eventType: 'View_Refer_Earn_CopyLink_Initiate',
  },
  View_Refer_Earn_CopyLink_Initiate_Success: {
    customEventSchemaId:
      TraderEventType.View_Refer_Earn_CopyLink_Initiate_Success,
    customEventSchemaVersion: 1,
    eventType: 'View_Refer_Earn_CopyLink_Initiate_Success',
  },
  View_Refer_Earn_SendInvite_Initiate: {
    customEventSchemaId: TraderEventType.View_Refer_Earn_SendInvite_Initiate,
    customEventSchemaVersion: 1,
    eventType: 'View_Refer_Earn_SendInvite_Initiate',
  },
  View_Refer_Earn_SendInvite_Initiate_sucess: {
    customEventSchemaId:
      TraderEventType.View_Refer_Earn_SendInvite_Initiate_sucess,
    customEventSchemaVersion: 1,
    eventType: 'View_Refer_Earn_SendInvite_Initiate_sucess',
  },
  View_Refer_Earn_SendInvite_Options: {
    customEventSchemaId: TraderEventType.View_Refer_Earn_SendInvite_Options,
    customEventSchemaVersion: 1,
    eventType: 'View_Refer_Earn_SendInvite_Options',
  },
  View_Refer_Earn_SendInvite_Options_Success: {
    customEventSchemaId:
      TraderEventType.View_Refer_Earn_SendInvite_Options_Success,
    customEventSchemaVersion: 1,
    eventType: 'View_Refer_Earn_SendInvite_Options_Success',
  },
  Refer_Earn_Back_button_initiate: {
    customEventSchemaId: TraderEventType.Refer_Earn_Back_button_initiate,
    customEventSchemaVersion: 1,
    eventType: 'Refer&Earn_Back_button_initiate',
  },
  Refer_Earn_Back_button_initiate_success: {
    customEventSchemaId:
      TraderEventType.Refer_Earn_Back_button_initiate_success,
    customEventSchemaVersion: 1,
    eventType: 'Refer&Earn_Back_button_initiate_success',
  },
  View_ReferralEarnings_Initiate: {
    customEventSchemaId: TraderEventType.View_ReferralEarnings_Initiate,
    customEventSchemaVersion: 1,
    eventType: 'View_ReferralEarnings_Initiate',
  },
  View_ReferralEarnings_Initiate_success: {
    customEventSchemaId: TraderEventType.View_ReferralEarnings_Initiate_success,
    customEventSchemaVersion: 1,
    eventType: 'View_ReferralEarnings_Initiate_success',
  },
  View_ReferralEarnings_SendReminders_Initiate: {
    customEventSchemaId:
      TraderEventType.View_ReferralEarnings_SendReminders_Initiate,
    customEventSchemaVersion: 1,
    eventType: 'View_ReferralEarnings_SendReminders_Initiate',
  },
  View_ReferralEarnings_SendReminders_Initiate_success: {
    customEventSchemaId:
      TraderEventType.View_ReferralEarnings_SendReminders_Initiate_success,
    customEventSchemaVersion: 1,
    eventType: 'View_ReferralEarnings_SendReminders_Initiate_success',
  },
  ReferralEarning_Back_button_initiate: {
    customEventSchemaId: TraderEventType.ReferralEarning_Back_button_initiate,
    customEventSchemaVersion: 1,
    eventType: 'ReferralEarning_Back_button_initiate',
  },
  ReferralEarnings_Back_button_initiate_success: {
    customEventSchemaId:
      TraderEventType.ReferralEarnings_Back_button_initiate_success,
    customEventSchemaVersion: 1,
    eventType: 'ReferralEarnings_Back_button_initiate_success',
  },
  QRCodeScanInitiate: {
    customEventSchemaId: TraderEventType.QRCodeScanInitiate,
    customEventSchemaVersion: 1,
    eventType: 'QRCodeScanInitiate',
  },
  Gp_Initiate: {
    customEventSchemaId: TraderEventType.Gp_Initiate,
    customEventSchemaVersion: 1,
    eventType: 'Gp_Initiate',
  },
  Generic_event: {
    customEventSchemaId: TraderEventType.Generic_event,
    customEventSchemaVersion: 1,
    eventType: 'Generic_event',
  },
  Home_CTA_CBLinkBankAccount_Initiate: {
    customEventSchemaId: TraderEventType.Home_CTA_CBLinkBankAccount_Initiate,
    customEventSchemaVersion: 1,
    eventType: 'Home_CTA_CBLinkBankAccount_Initiate',
  },
  Home_CBViewAccount_All_View: {
    customEventSchemaId: TraderEventType.Home_CBViewAccount_All_View,
    customEventSchemaVersion: 1,
    eventType: 'Home_CBViewAccount_All_View',
  },
  Home_CBViewAccount_Select: {
    customEventSchemaId: TraderEventType.Home_CBViewAccount_Select,
    customEventSchemaVersion: 1,
    eventType: 'Home_CBViewAccount_Select',
  },
  DT_Referral: {
    customEventSchemaId: TraderEventType.DT_Referral,
    customEventSchemaVersion: 1,
    eventType: 'DT referral',
  },
  Ninja_pay_credit_limit_failed: undefined,
  Ninja_pay_request_failed: undefined,
  Terms_initiate: undefined,
  Privacy_policy_initiate: undefined,
  Menu_language_initiate: undefined,
  Menu_language_success: undefined,
  Menu_language_failure: undefined,
  Menu_help_initiate: undefined,
  Menu_logout_initiate: undefined,
  Menu_select: undefined,
  Instaod_home_success: undefined,
  Instaod_land_initiate: undefined,
  Instaod_land_success: undefined,
  NinjaPay_ShareInvite_Initiate: undefined,
  NinjaPay_EnterReferrerNo_Error: undefined,
  Ninja_Pay_HomePage: {
    customEventSchemaId: TraderEventType.Ninja_Pay_HomePage,
    customEventSchemaVersion: 1,
    eventType: 'Ninja Pay HomePage',
  },
};
