export const TRANSACTIONS_FILTER_LABELS = {
  ALL: 'all',
  PAID: 'paid',
  RECEIVED: 'received',
  ADDED: 'added',
  CASHBACK: 'cashback',
};
export const TRANSACTIONS_FILTER_OPTIONS = {
  ALL: {
    label: TRANSACTIONS_FILTER_LABELS.ALL,
    route: '/transactions/all',
    identifier: 1,
  },
  PAID: {
    label: TRANSACTIONS_FILTER_LABELS.PAID,
    route: '/transactions/paid',
    identifier: 2,
  },
  RECEIVED: {
    label: TRANSACTIONS_FILTER_LABELS.RECEIVED,
    route: '/transactions/received',
    identifier: 3,
  },
  ADDED: {
    label: TRANSACTIONS_FILTER_LABELS.ADDED,
    route: '/transactions/added',
    identifier: 4,
  },
  CASHBACK: {
    label: TRANSACTIONS_FILTER_LABELS.CASHBACK,
    route: '/transactions/cashback',
    identifier: 5,
  },
};

export const TRANSACTION_TYPES = {
  DEBIT: 'DEBIT',
  CREDIT: 'CREDIT',
  RECHARGE: 'RECHARGE',
  WITHDRAW: 'WITHDRAW',
};

export const PAYMENT_TYPES = {
  PAY_LATER: 'PAY_LATER',
  CASHBACK: 'CASHBACK',
  CASH: 'CASH',
  RTGS: 'RTGS',
  ONLINE: 'ONLINE',
};

export const PAYMENT_STATUS = {
  INIT: 'INIT',
  COMPLETED: 'COMPLETED',
  PENDING: 'PENDING',
  REQUESTED: 'REQUESTED',
  FAILED: 'FAILED',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
  REVERSED: 'REVERSED',
  PENDING_COMPLETION: 'PENDING_COMPLETION',
};

export const TRANSACTION_REF_TYPE = {
  B2S2: 'B2S2',
  D2S: 'D2S',
};

export const PDF_LINK =
  'https://s3.amazonaws.com/prod-workflow-docs/workflow/2c95772a-d2cf-40a2-9b82-d59be0196fb5/0_1659686643421.pdf';

export const SUPPORT_MOBILE_NUMBER = '08035018270';
export const CHAT_SUPPORT = '+919886661491';

export const iconSize = 24;

export const QueryKeys = {
  dashboardData: 'dashboardData',
  accountsData: 'accountsData',
  pgProductsData: 'pgProductsData',
  pgProductsApply: 'pgProductsApply',
  checkConsentStatus: 'checkConsentStatus',
  postConsentStatus: 'postConsentStatus',
  businessProfile: 'businessProfile',
  pgDetails: 'pgDetails',
  kycSummary: 'kycSummary',
  kycDocumentStatus: 'kycDocumentStatus',
  kycDigioInitiate: 'kycDigioInitiate',
  kycDigioCallbackStatus: 'kycDigioCallbackStatus',
  kycCreateSrmTicket: 'kycCreateSrmTicket',
  kycUserActionStatusUpdate: 'kycUserActionStatusUpdate',
  getCreditScore: 'getCreditScore',
  getCibilScoreFeatureCalculation: 'getCibilScoreFeatureCalculation',
  pgTriggerESignDigio: 'pgTriggerESignDigio',
  rewardData: 'rewardData',
  cashTransaction: 'cashTransaction',
  cashTransactionDetails: 'cashTransactionDetails',
  bankDetails: 'bankDetails',
  bankTransfer: 'bankTransfer',
  upiTransfer: 'upiTransfer',
  bankTransferOtp: 'bankTransferOtp',
  verifyUPI: 'verifyUPI',
  verifyBank: 'verifyBank',
  addBenificiary: 'addBenificiary',
  validatePayment: 'validatePayment',
  getShortFormDetails: 'getShortFormDetails',
  organizationData: 'organizationData',
  rolesData: 'rolesData',
  snOrganizationDetails: 'snOrganizationDetails',
  businessType: 'businessType',
  categoryType: 'categoryType',
  countryList: 'countryList',
  statesList: 'statesList',
  ninjaOneTag: 'ninjaOneTag',
  referAndEarn: 'referAndEarn',
  rewardPoints: 'rewardPoints',
  brandsData: 'brandsData',
  dashboardBackgroundData: 'dashboardBackgroundData',
  subscriptionData: 'subscriptionData',
  createFeature: 'createFeature',
  unsubscriptionOtp: 'unsubscriptionOtp',
  unsubscriptionOtpSubmit: 'unsubscriptionOtpSubmit',
  actionUpdate: 'actionUpdate',
  sellerFTB: 'sellerFTB',
};

export enum PGStatus {
  APPROVED = 'APPROVED',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
  VERIFICATION_COMPLETE = 'VERIFICATION_COMPLETE',
}

export enum ESignStatus {
  REQUESTED = 'REQUESTED',
  INPROGRESS = 'INPROGRESS', // ESignStatus.REQUESTED && DIGIO_REDIRECTION_STATUS.SUCCESS (DB will not store INPROGRESS status)
  COMPLETED = 'COMPLETED',
}

export enum DIGIO_REDIRECTION_STATUS {
  SUCCESS = 'success',
  FAILURE = 'failure',
  CANCEL = 'cancel',
}

export enum ENachStatus {
  PARTIAL = 'PARTIAL',
  INPROGRESS = 'INPROGRESS', // ENachStatus.PARTIAL && DIGIO_REDIRECTION_STATUS.SUCCESS (DB will not store INPROGRESS status)
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  REGISTER_SUCCESS = 'REGISTER_SUCCESS',
  REGISTER_FAILED = 'REGISTER_FAILED',
  DEST_REGISTER_SUCCESS = 'DEST_REGISTER_SUCCESS', // Physical Nach Success
}

export enum MandateType {
  PHYSICAL = 'PHYSICAL',
  API_MANDATE = 'API_MANDATE',
}

export const CONSENT_ID = 1;

export enum ExistingLoanStatus {
  SUBMITTED_AND_PENDING_APPROVAL = 'SUBMITTED_AND_PENDING_APPROVAL',
  DOCUMENT_COLLECTED_AND_PENDING_VERIFICATION = 'DOCUMENT_COLLECTED_AND_PENDING_VERIFICATION',
  DOCUMENT_VERIFIED_AND_PENDING_APPROVAL = 'DOCUMENT_VERIFIED_AND_PENDING_APPROVAL',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

export const UserTypeID = {
  KYC: 3,
  KYC_LITE: 5,
};

export enum KYC_STATUS {
  PENDING = 1,
  VERIFIED = 2,
  PROGRESS = 3,
}

export enum KYC_LITE_STATUS {
  PENDING = 1,
  VERIFIED = 2,
  PROGRESS = 3,
}

export enum APP_NAME {
  PAYMENT_ASSURANCE = 'Payment Assurance',
  INSTA_OD = 'Insta OD',
  INVESTMENT = 'Smart Invest',
  NINJA_ONE = 'Ninja Trade Card',
  NINJA_PAYSURE = 'Ninja Pay Sure',
  NINJA_WIFI = 'Ninja Wi-Fi',
  Mandi_News = 'Mandi News',
  Ninja_Kirana = 'Ninja Kirana',
  AGRI_TRADE_BUREAU = 'Agri trade Bureau',
  MY_QR = 'My QR',
}

export const MANDI_NEWS_APP_VERSION = 162;
export const MANDI_NEWS_NEW_URL_VERSION = 189;

export const NFC_COMMERCE_DATA = {
  app_detail: { source: 'trader', role: 'TANENT' },
  homePageUrl: `/ninja-agnet-web/order/list`,
  providerId: `25c3ed73-cd4b-4dac-8a7c-473a6e359f38`,
};

export const CONSENT_STATUS = {
  APPROVED: 'APPROVED',
};

export const NINJA_WIFI_ENABLE_FACILITY = [
  '2',
  '249',
  '239',
  '3288',
  '3914',
  '4684',
];

export enum ALERTS_TYPES {
  VIEWED = 'VIEWED',
  PENDING = 'PENDING',
}

export enum ALERT_EXPIRY_TYPE {
  VIEWED_AFTER = 'VIEWED_AFTER',
  USER_ACTION = 'USER_ACTION',
  SYSTEM = 'SYSTEM',
}

export enum ALERT_VIEW_MODE {
  CRITICAL = 'CRITICAL',
  SUCCESS = 'SUCCESS',
  INFO = 'INFO',
}

export const mandiNewsExperimentVersion = 172;

export enum CATEGORY_LIST {
  Fruits = 'Fruits',
  Vegetables = 'Vegetables',
}

export const BUSINESS_PROFILE_CATEGORY_ID = {
  Fruits_Vegetables: '1',
  Staples: '2',
};

export const REALM_TAGS = {
  NINJA_ONE: 'NINJA_ONE',
};

export enum KYCStatusDashBoard {
  PENDING = 'PENDING',
  PROGRESS = 'PROGRESS',
  VERIFIED = 'VERIFIED',
}

export enum CommerceTypes {
  SELLER = 'sell',
  BUYER = 'buy',
}

export const PgPayment = {
  RAZORPAY: 'RAZORPAY',
  PAYMENT_PARTNER_KEY_ID: 'PAYMENT_PARTNER_KEY_ID',
  PAYMENT_PARTNER: 'PAYMENT_PARTNER',
  PAYMENT_PARTNER_ORDER_ID: 'PAYMENT_PARTNER_ORDER_ID',
};

export const Owner = 'OWNER';
export const SettlementUTR = 'SETTLEMENT_UTR';
export const SettledAmount = 'SETTLED_AMOUNT';
export const SettlementTime = 'SETTLEMENT_TIMESTAMP';
export const AppName = {
  NTC: 'NTC',
  loans_footer: 'loans_footer',
  MandiNews: 'MandiNews',
};

export const DEVICE_CONSTANTS = {
  ORIGINAL_DEVICE_ID: 'originalDeviceId',
  ADVERTISING_ID: 'advertisingId',
  FIREBASE_APP_ID: 'firebaseAppInstanceId',
};

export const CATEGORY_HEADING = {
  staples: 'Staples',
  fruitsAndVegetables: 'FruitsAndVegetables',
};

export const CATEGORY_TYPE = {
  turmeric: 'Turmeric',
  Nutmeg: 'Nutmeg',
};
