export const STATIC_REALM_ID = 'dd180bca-465a-470a-abe4-9d5a15ded551';
export const STATIC_USER_ID = '1';

function getWorkFlowURL() {
  return `${process.env.NEXT_PUBLIC_WORKFLOW_URL}/workflow-engine`;
}

const paths = {
  requestOTPUrl: () =>
    `${getWorkFlowURL()}/${STATIC_REALM_ID}/${STATIC_USER_ID}/v1/execution/service/runWithNoAuth/user-login-otp-notification-service-config`,
  submitOTPUrl: () =>
    `${getWorkFlowURL()}/${STATIC_USER_ID}/${STATIC_USER_ID}/v1/execution/service/runWithNoAuth/onboard-traders`,
  traderHomeScreen: (realmId: string, userId: number) =>
    `${getWorkFlowURL()}/${realmId}/${userId}/v1/execution/service/run/trader-app-home-screen-v1`,
  traderReferralBeta: (userId: number, realmId: string) =>
    `${getWorkFlowURL()}/${realmId}/${userId}/v1/execution/service/run/trader-referral-beta`,
  firebaseTokenUrl: (userId: number) =>
    `${process.env.NEXT_PUBLIC_WORKFLOW_URL}/iam/firebase/token/${userId}`,
  traderAppHomeScreenV1: (realmId: string, userId: number) =>
    `${getWorkFlowURL()}/${realmId}/${userId}/v1/execution/service/run/trader-app-home-screen-v1`,
  traderAppHomeScreenBackground: (realmId: string, userId: number) =>
    `${getWorkFlowURL()}/${realmId}/${userId}/v1/execution/service/run/trader-app-home-screen-background`,
  getConnectedBankAccountsV2: (
    realmId: string,
    systemUserId: number,
    contact: string,
    fetchBalance = false
  ) =>
    `${process.env.NEXT_PUBLIC_VAN_URL}/${realmId}/${systemUserId}/connected-account/v2/getAccounts?mobile=${contact}&fetchBalance=${fetchBalance}`,

  traderWalletInfo: (realmId: string, userId: number) =>
    `${getWorkFlowURL()}/${realmId}/${userId}/v1/execution/service/run/trader-wallet-info`,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  transactionHistory: (realmId: string, userId: string, query: any) =>
    `${
      process.env.NEXT_PUBLIC_PAYMENT_URL
    }/${realmId}/${userId}/cashTransactions?fromDate=${query.fromDate}&toDate=${
      query.toDate
    }&ignoreOwnerTypesIn=QR&pageNo=1&pageSize=100&includePartyDetails=true&latestFirst=true&sendExtraDetails=true&ignoreD2S=${
      query.ignoreD2S || false
    }&ninjapayStatus=${query.ninjapayStatus || ''}`,
  generateDocument: (realmId: string, userId: string) =>
    `${getWorkFlowURL()}/${realmId}/${userId}/v1/execution/service/run/document-generator-service-config`,
  fetchDocument: (realmId: string, userId: number) =>
    `${getWorkFlowURL()}/${realmId}/${userId}/v1/execution/service/run/document-retrieve-service-config`,
  consentURL: (realmId: string, userId: number) =>
    `${process.env.NEXT_PUBLIC_ONBOARD_URL}/${realmId}/${userId}/v1/execution/service/run/free-flow-consent-listing-service-config-new`,
  eventsUrl: (realmId: string, userId: number) => {
    if (userId === 1 || Number(realmId) === 1) {
      return `${process.env.NEXT_PUBLIC_EVENT_WORKFLOW_URL}/workflow-engine/1/1/v1/execution/service/runWithNoAuth/old-app-events-push`;
      // return `${process.env.NEXT_PUBLIC_WORKFLOW_URL}/workflow-engine/1/1/v1/execution/event/runWithNoAuth/AppEvent`;
    }
    return `${process.env.NEXT_PUBLIC_EVENT_WORKFLOW_URL}/workflow-engine/${realmId}/${userId}/v1/execution/service/run/old-app-events-push`;
  },
  pgFetchMultipleProfile: (realmId: string, userId: number) =>
    `${process.env.NEXT_PUBLIC_ONBOARD_URL}/${realmId}/${userId}/v1/execution/service/run/pg-fetch-multiple-profile`,
  pgFetchProducts: (realmId: string, userId: number) =>
    `${process.env.NEXT_PUBLIC_ONBOARD_URL}/${realmId}/${userId}/v1/execution/service/run/pg-products-fetch-apply`,
  updateLspMandate: (realmId: string, userId: number) =>
    `${process.env.NEXT_PUBLIC_ONBOARD_URL}/${realmId}/${userId}/v1/execution/service/run/lsp-user-lender-mandate-update`,
  saveLspMandate: (realmId: string, userId: number) =>
    `${process.env.NEXT_PUBLIC_ONBOARD_URL}/${realmId}/${userId}/v1/execution/service/run/lsp-user-lender-mandate-save`,
  pgTriggerESignDigio: (realmId?: string, systemUserId?: number) =>
    `${process.env.NEXT_PUBLIC_WORKFLOW_URL}/workflow-engine/${realmId}/${systemUserId}/v1/execution/service/run/lsp-create-loan-agreement`,
  pgTriggerENachDigio: (realmId?: string, systemUserId?: number) =>
    `${process.env.NEXT_PUBLIC_WORKFLOW_URL}/workflow-engine/${realmId}/${systemUserId}/v1/execution/service/run/lsp-create-mandate-save`,
  updateAdditionalLoanInfo: (realmId?: string, systemUserId?: number) =>
    `${process.env.NEXT_PUBLIC_WORKFLOW_URL}/workflow-engine/${realmId}/${systemUserId}/v1/execution/service/run/create-loan-additional-info`,
  getFungibleLimit: (realmId?: string, systemUserId?: number) =>
    `${process.env.NEXT_PUBLIC_WORKFLOW_URL}/workflow-engine/${realmId}/${systemUserId}/v1/execution/service/run/get-fungible-limit`,
  pgDocStatus: (realmId?: string, userId?: number) =>
    `${process.env.NEXT_PUBLIC_WORKFLOW_URL}/workflow-engine/${realmId}/${userId}/v1/execution/service/run/pg-kyc-doc-status`,
  traderAppPGDetails: (realmId: string, userId: number) =>
    `${process.env.NEXT_PUBLIC_ONBOARD_URL}/${realmId}/${userId}/v1/execution/service/run/fetch-pg-details`,
  pgApplyProducts: (realmId: string, userId: number) =>
    `${process.env.NEXT_PUBLIC_ONBOARD_URL}/${realmId}/${userId}/v1/execution/service/run/pg-program-approve`,
  getConsentCheckUrl: (realmId: string, userId: number) =>
    `${process.env.NEXT_PUBLIC_ONBOARD_URL}/${realmId}/${userId}/v1/execution/service/run/get-consent-string-for-kyc`,
  getConsentPostUrl: (realmId: string, userId: number) =>
    `${process.env.NEXT_PUBLIC_ONBOARD_URL}/${realmId}/${userId}/v1/execution/service/run/create-consent-for-kyc`,
  getKycSummaryUrl: (realmId: string, userId: number) =>
    `${process.env.NEXT_PUBLIC_ONBOARD_URL}/${realmId}/${userId}/v1/execution/service/run/kyc-user-action-summary`,
  getKycDocumentStatus: (realmId: string, userId: number) =>
    `${process.env.NEXT_PUBLIC_ONBOARD_URL}/${realmId}/${userId}/v1/execution/service/run/kyc-fetch-document-status`,
  kycDigioInitiateUrl: (realmId: string, userId: number) =>
    `${process.env.NEXT_PUBLIC_ONBOARD_URL}/${realmId}/${userId}/v1/execution/service/run/kyc-digio-initiate`,
  kycDigioCallBackStatusUrl: (realmId: string, userId: number) =>
    `${process.env.NEXT_PUBLIC_ONBOARD_URL}/${realmId}/${userId}/v1/execution/service/run/kyc-digio-callback-status-fetch`,
  kycCreateSrmTicket: (realmId: string, userId: number) =>
    `${process.env.NEXT_PUBLIC_ONBOARD_URL}/${realmId}/${userId}/v1/execution/service/run/kyc-ticket-creation`,
  kycUserActionStatus: (realmId: string, userId: number) =>
    `${process.env.NEXT_PUBLIC_ONBOARD_URL}/${realmId}/${userId}/v1/execution/service/run/kyc-user-action-update-status`,
  getCreditScoreUrl: (realmId: string, userId: number) =>
    `${process.env.NEXT_PUBLIC_ONBOARD_URL}/${realmId}/${userId}/v1/execution/service/run/get-cb-report-all-agencies-start`,
  getCibilScoreFeatureCalculationUrl: (realmId: string, userId: number) =>
    `${process.env.NEXT_PUBLIC_ONBOARD_URL}/${realmId}/${userId}/v1/execution/service/run/update-credit-report-realm`,
  getSubmitCrifUrl: (realmId: string, userId: number) =>
    `${process.env.NEXT_PUBLIC_ONBOARD_URL}/${realmId}/${userId}/v1/execution/service/run/get-cb-report-crif-questionnaire`,
  getPgFees: (
    realmId: string,
    userId: number,
    transactionType = 'DEBIT',
    status = 'REQUESTED',
    sendAdditionalInfo = 'true',
    transactionRefType = 'PG_FEES'
  ) =>
    `${process.env.NEXT_PUBLIC_FINTRANS_URL}/${realmId}/${userId}/cashTransactions/getByTransactionState?transactionType=${transactionType}&status=${status}&sendAdditionalInfo=${sendAdditionalInfo}&transactionRefType=${transactionRefType}`,
  getAccountBalanceV2: (
    realmId: string,
    systemUserId: number,
    contactNumber: string,
    accountId: number
  ) =>
    `${process.env.NEXT_PUBLIC_VAN_URL}/${realmId}/${systemUserId}/account/v2/accountId/${accountId}/balance?mobile=${contactNumber}`,
  request: (realmId: string, userId: string) =>
    `${process.env.NEXT_PUBLIC_WORKFLOW_URL}/workflow-engine/${realmId}/${userId}/v1/execution/request`,
  getPartyUserDetails: (realmId: string, userId: number) =>
    `${process.env.NEXT_PUBLIC_WORKFLOW_URL}/workflow-engine/${realmId}/${userId}/v1/execution/service/run/find-by-userIds`,
  getRewardData: () =>
    `${process.env.NEXT_PUBLIC_PAYMENT_ENTITY_URL}/entity-services/api/v1/realm/1234/user/1234/ProductsMaster`,
  redeemReward: (realmId: any, userId: number) =>
    `${process.env.NEXT_PUBLIC_WORKFLOW_URL}/workflow-engine/${realmId}/${userId}/v1/execution/service/runWithNoAuth/redeem-nfc-reward`,
  getCashTransactions: (realmId: string, userId: number, params: string) =>
    `${process.env.NEXT_PUBLIC_FINTRANS_URL}/${realmId}/${userId}/cashTransactions${params}`,
  useGetBankDetailsV2: (realmId: string, userId: number) =>
    `${process.env.NEXT_PUBLIC_WORKFLOW_URL}/workflow-engine/${realmId}/${userId}/v1/execution/service/run/multi-bank-account-listing-payee`,
  getBankTransfer: (realmId: string, userId: number, paymentMode?: string) =>
    `${
      process.env.NEXT_PUBLIC_WORKFLOW_URL
    }/workflow-engine/${realmId}/${userId}/v1/execution/service/run/${
      paymentMode === 'UPI' ? 'upi-transfer' : 'bank-transfer'
    }`,
  getRecordCash: (realmId: string, userId: number) =>
    `${process.env.NEXT_PUBLIC_WORKFLOW_URL}/workflow-engine/${realmId}/${userId}/v1/execution/service/run/record-cash`,
  getBankTransferOtp: (realmId: string, userId: number) =>
    `${process.env.NEXT_PUBLIC_WORKFLOW_URL}/workflow-engine/${realmId}/${userId}/v1/execution/service/run/transfer-otp-notification-service-config`,
  getUpiVerify: (realmId: string, userId: number) =>
    `${process.env.NEXT_PUBLIC_WORKFLOW_URL}/workflow-engine/${realmId}/${userId}/v1/execution/service/run/verify-upi`,
  getBankVerify: (realmId: string, userId: number) =>
    `${process.env.NEXT_PUBLIC_WORKFLOW_URL}/workflow-engine/${realmId}/${userId}/v1/execution/service/run/bank-details-verify`,
  getAddBenificiary: (realmId: string, userId: number) =>
    `${process.env.NEXT_PUBLIC_WORKFLOW_URL}/workflow-engine/${realmId}/${userId}/v1/execution/service/run/add-benificiary`,
  getPaymentValidationURL: (realmId: string, userId: number) =>
    `${process.env.NEXT_PUBLIC_PAYMENT_VAN_URL}/${realmId}/${userId}//payments/validate`,
  getShortFormDetails: (realmId: string, userId: number) =>
    `${process.env.NEXT_PUBLIC_ONBOARD_URL}/${realmId}/${userId}/v1/execution/service/run/pg-fetch-short-form-details`,
  updateShortFormDetails: (realmId: string, userId: number) =>
    `${process.env.NEXT_PUBLIC_ONBOARD_URL}/${realmId}/${userId}/v1/execution/service/run/pg-update-short-form-details`,
  getPincodeAddress: (realmId: string, userId: number) =>
    `${process.env.NEXT_PUBLIC_ONBOARD_URL}/${realmId}/${userId}/v1/execution/service/run/pincode-address`,

  getBusinessType: (realmId: string, userId: string) =>
    `${process.env.NEXT_PUBLIC_WORKFLOW_URL}/profile-service/${realmId}/${userId}/category/get-list/Business_Type`,
  getCountryList: (realmId: string, userId: string) =>
    `${process.env.NEXT_PUBLIC_WORKFLOW_URL}/profile-service/${realmId}/${userId}/global/default-values`,
  getStateList: (realmId: string, userId: string, country_id: number) =>
    `${process.env.NEXT_PUBLIC_WORKFLOW_URL}/profile-service/${realmId}/${userId}/state/${country_id}?skip=0&limit=500`,
  getCityList: (
    realmId: string,
    userId: string,
    country_id: number,
    state_id: number
  ) =>
    `${
      process.env.NEXT_PUBLIC_WORKFLOW_URL
    }/profile-service/${realmId}/${userId}/city?state=${
      state_id ? state_id : ''
    }&country=${country_id}&skip=0&limit=500`,
  getMarketList: (city_id: number) =>
    `${process.env.NEXT_PUBLIC_BUSINESS_PROFILE_MARKET_URL}/get-ninja-market-list?city_id=${city_id}`,
  getCategoryList: (realmId: string, userId: string) =>
    `${process.env.NEXT_PUBLIC_WORKFLOW_URL}/profile-service/${realmId}/${userId}/sn-category/parent-categories`,
  imageUpload: (realmId: string, userId: string) =>
    `${process.env.NEXT_PUBLIC_WORKFLOW_URL}/profile-service/${realmId}/${userId}/media/upload-document`,
  getSubCategoryList: (realmId: string, userId: string, queryparams: any) =>
    `${process.env.NEXT_PUBLIC_WORKFLOW_URL}/profile-service/${realmId}/${userId}/sn-category/child-categories-by-parent?${queryparams}`,
  updateOrganization: (realmId: string, userId: string, realmIdUser: string) =>
    `${process.env.NEXT_PUBLIC_WORKFLOW_URL}/profile-service/${realmId}/${userId}/organization/${realmIdUser}`,
  createProfile: (realmId: string, userId: string) =>
    `${process.env.NEXT_PUBLIC_WORKFLOW_URL}/profile-service/${realmId}/${userId}/sn-org-profile`,
  getRealmId: (realmId: string, userId: string) =>
    `${process.env.NEXT_PUBLIC_WORKFLOW_URL}/profile-service/${realmId}/${userId}/sn-org-profile/fetchMultiple`,
  getProfileDetail: (realmId: string, userId: string) =>
    `${process.env.NEXT_PUBLIC_WORKFLOW_URL}/workflow-engine/${realmId}/${userId}/v1/execution/service/run/business-profile-performance-data`,
  uploadMediaDetails: (realmId: string, userId: string, id: any) =>
    `${process.env.NEXT_PUBLIC_WORKFLOW_URL}/profile-service/${realmId}/${userId}/sn-org-profile/${id}/media`,
  getOrganization: (realmId: string, userId: string, id: any) =>
    `${process.env.NEXT_PUBLIC_WORKFLOW_URL}/profile-service/${realmId}/${userId}/organization/get-organization-profile/${id}`,
  getSnOrgProfile: (realmId: string, userId: string, id: any) =>
    `${process.env.NEXT_PUBLIC_WORKFLOW_URL}/profile-service/${realmId}/${userId}/sn-org-profile/${id}`,
  getOrganizationProfile: (realmId: string, userId: string, id: any) =>
    `${process.env.NEXT_PUBLIC_WORKFLOW_URL}/profile-service/${realmId}/${userId}/organization/${id}`,
  getRoles: () =>
    `${process.env.NEXT_PUBLIC_BUSINESS_PROFILE_URL}/ninja-global/roles/get-roles`,
  postConsentPermission: (realmId: string, userId: string) =>
    `${process.env.NEXT_PUBLIC_WORKFLOW_URL}/workflow-engine/${realmId}/${userId}/v1/execution/service/run/generic-urii-create`,
  postAlertView: (realmId: string, userId: string) =>
    `${process.env.NEXT_PUBLIC_WORKFLOW_URL}/workflow-engine/${realmId}/${userId}/v1/execution/service/run/publish-alert-events`,
  fetchMarketNameById: (realmId: string, userId: string) =>
    `${process.env.NEXT_PUBLIC_WORKFLOW_URL}/workflow-engine/${realmId}/${userId}/v1/execution/service/run/analytics-report-query`,
  postAddressVerificationOtp: (realmId: string, userId: string) =>
    `${process.env.NEXT_PUBLIC_WORKFLOW_URL}/workflow-engine/${realmId}/${userId}/v1/execution/service/run/residential-address-cpv-verification`,
  postOriginalDeviceDetails: (realmId: string, userId: string) =>
    `${process.env.NEXT_PUBLIC_WORKFLOW_URL}/workflow-engine/${realmId}/${userId}/v1/execution/service/run/additional-detail-update`,
  getNinjaOneTag: (realmId: string, userId: string) =>
    `${process.env.NEXT_PUBLIC_WORKFLOW_URL}/workflow-engine/${realmId}/${userId}/v1/execution/service/run/get-realm-by-id`,
  getReferralDetails: (realmId: string, userId: number) =>
    `${process.env.NEXT_PUBLIC_PAYMENT_ENTITY_URL}/ncpay/api/v1/referral/realm/${realmId}/user/${userId}/first-investment/referrals?referralMedium=DT_Referral`,
  getAnalyticsQuery: (realmId: string, userId: string) =>
    `${process.env.NEXT_PUBLIC_WORKFLOW_URL}/workflow-engine/${realmId}/${userId}/v1/execution/service/run/analytics-report-query`,
  getBrands: (realmId: string, userId: string) =>
    `${process.env.NEXT_PUBLIC_WORKFLOW_URL}/workflow-engine/${realmId}/${userId}/v1/execution/service/run/get-brands`,
  updateURI: (realm: string, userId: string) =>
    `${process.env.NEXT_PUBLIC_WORKFLOW_URL}/workflow-engine/${realm}/${userId}/v1/execution/service/run/update-realm-additional-details`,
  getSubscriptionData: (realmId: string, userId: string) =>
    `${process.env.NEXT_PUBLIC_WORKFLOW_URL}/workflow-engine/${realmId}/${userId}/v1/execution/service/run/check-mandate-and-subscription-status`,
  createFeature: (realmId: string, userId: string) =>
    `${process.env.NEXT_PUBLIC_WORKFLOW_URL}/workflow-engine/${realmId}/${userId}/v1/execution/service/run/enable-kirana-seller-subscription`,
  getSellerSubscriptionOtp: (realmId: string, userId: string) =>
    `${process.env.NEXT_PUBLIC_WORKFLOW_URL}/workflow-engine/${realmId}/${userId}/v1/execution/service/run/seller-subscription-otp-generation`,
  submitSellerSubscriptionOtp: (realmId: string, userId: string) =>
    `${process.env.NEXT_PUBLIC_WORKFLOW_URL}/workflow-engine/${realmId}/${userId}/v1/execution/service/run/seller-unsubscription-otp-verification-and-update-auto-renewal`,
  updateAction: (realmId: string, userId: string) =>
    `${process.env.NEXT_PUBLIC_WORKFLOW_URL}/ncpay/api/v1/realm/${realmId}/user/${userId}/bnpl/config/actions/update`,
  fetchSellerFTB: (userId: string) =>
    `${process.env.NEXT_PUBLIC_PAYMENT_ENTITY_URL}/ncpay/api/v1/realms/1/users/${userId}/seller-details/FTBDetails`,
  referralPayoutDetails: (realmId: string, userId: number) =>
    `${process.env.NEXT_PUBLIC_WORKFLOW_URL}/workflow-engine/${realmId}/${userId}/v1/execution/service/run/analytics-report-query`,
  updateLanguagePreference: (realmId: string, userId: number) =>
    `${process.env.NEXT_PUBLIC_WORKFLOW_URL}/workflow-engine/${realmId}/${userId}/v1/execution/service/run/update-user-primary-language-preference`,
};
export default paths;
